<template>
  <div class="pagePadding">
    <div>
      <span class="pageBtn finger btnReset" @click="back">返回</span>
    </div>
    <div class="nav">
      <FormLine :list="list" :nowIndex="nowIndex"></FormLine>
    </div>
    <div class="tabelDiv">
      <Table :productList="listColumns" :productData="listData" :isLoad="isLoad">
        <div slot="summary" class="summary">
          <div>
            <span style="margin-right: 20px;"
              >总数量：<span class="color389">{{ detailsInfo.total_number }}</span></span
            >
            <span>合计金额：</span>
            <span class="color389">¥{{ detailsInfo.total_amount }}</span>
          </div>
        </div>
      </Table>
    </div>
    <div class="other">其它信息</div>
    <div class="fromDiv">
      <div class="formLine">
        <FormLabel label="订单号"></FormLabel>
        <div class="allWidth fl">{{ detailsInfo.order_number }}</div>
      </div>
      <div class="formLine borderTopNone">
        <FormLabel label="下单时间"></FormLabel>
        <div class="allWidth fl">{{ $moment(detailsInfo.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
      </div>
      <div v-if="detailsInfo.logistics_info">
        <div class="formLine borderTopNone" v-for="(item, index) in detailsInfo.logistics_info" :key="index">
          <FormLabel :label="'物流信息' + (index + 1)"></FormLabel>
          <div class="allWidth fl">
            <span>{{ item.logistics_company }}</span
            ><span class="orderNum" @click="showLogis(item)">{{ item.logistics_code }}</span
            ><span>{{ item.delivery_time }}</span>
            <img src="../../assets/images/addLogistics.png" v-if="index == detailsInfo.logistics_info.length - 1" style="margin: 0 20px;" @click="addLogis" />
            <img src="../../assets/images/deleteLogistics.png" v-if="index == detailsInfo.logistics_info.length - 1" @click="deleteLogis(item)" />
          </div>
        </div>
      </div>
      <div v-else class="formLine borderTopNone">
        <FormLabel :label="'物流信息'"></FormLabel>
        <div class="allWidth fl">
          <img src="../../assets/images/addLogistics.png" style="margin: 0 20px 0 0;" @click="addLogis" />
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FormLabel label="收货地址"></FormLabel>
        <div class="allWidth fl">{{ detailsInfo.address }}</div>
      </div>
      <div class="formLine borderTopNone">
        <FormLabel label="联系人"></FormLabel>
        <div class="allWidth fl">{{ detailsInfo.contact_name }}</div>
      </div>
      <div class="formLine borderTopNone">
        <FormLabel label="联系电话"></FormLabel>
        <div class="allWidth fl">{{ detailsInfo.phone }}</div>
      </div>
      <div class="formLine borderTopNone">
        <FormLabel label="备注信息"></FormLabel>
        <div class="allWidth fl nowrap">{{ detailsInfo.note ? detailsInfo.note : '无' }}</div>
      </div>
    </div>
    <Modal v-model="addState" :title="logisTitle" class-name="vertical-center-modal" width="505" :mask-closable="false">
      <div v-if="addState">
        <div class="clearfix">
          <div class="item">
            <span>物流公司：</span>
            <i-input placeholder="请输入" :value="logisticsInfo.logistics_company" class="iviewIptWidth373" disabled></i-input>
          </div>
          <div class="item"><span>物流单号：</span><i-input type="number" placeholder="请输入" class="iviewIptWidth373" v-model="logisticsInfo.logistics_code" :disabled="logisTitle == '物流详情'"></i-input></div>
          <div class="item"><span>发货时间：</span><DatePicker type="datetime" class="iviewIptWidth373" :value="logisticsInfo.delivery_time" placeholder="请选择" :disabled="logisTitle == '物流详情'" @on-change="changeTime"></DatePicker></div>
        </div>
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="addLogistics" v-if="logisTitle == '新增物流'">确定</span>
      </div>
    </Modal>
    <!--    删除物流-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
  </div>
</template>

<script>
import Table from '@/components/table'
import FormLine from '@/components/formLine'
import FormLabel from '@/components/formLabel'
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'purchaseOrderDetails',
  components: {
    Table,
    FormLine,
    FormLabel,
    TipsComponent
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id
    this.queryDetails(this.id)
  },
  data() {
    return {
      deleteStatus: false,
      logisTitle: '新增物流', // 物流弹窗
      id: '',
      addState: false,
      isLoad: false,
      nowIndex: 0,
      list: [
        {
          title: '待确认',
          value: '1'
        },
        {
          title: '待发货',
          value: '8'
        },
        {
          title: '部分发货',
          value: '9'
        },
        {
          title: '待收货',
          value: '2'
        },
        {
          title: '待开票',
          value: '4'
        },
        {
          title: '已完成',
          value: '5'
        }
      ],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65
        },
        {
          title: '物料号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 150
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 150
        },
        {
          title: '注册证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 150
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 200
        },
        {
          title: '单价',
          key: 'unit_price',
          align: 'center',
          width: 133
        },
        {
          title: '数量',
          key: 'number',
          align: 'center',
          width: 133
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 133
        },
        {
          title: '包装规格',
          key: 'packing_size',
          align: 'center',
          width: 133
        },
        {
          title: '小计',
          key: 'subtotal',
          align: 'center',
          width: 133
        }
      ],
      listData: [],
      detailsInfo: {},
      logisticsInfo: {
        logistics_company: '顺风',
        logistics_code: '',
        delivery_time: ''
      }
    }
  },
  methods: {
    // 新增物流改变时间
    changeTime(e) {
      if (e) {
        this.logisticsInfo.delivery_time = e
      } else {
        this.logisticsInfo.delivery_time = ''
      }
    },
    // 确认删除
    sureDelete() {
      let postObj = {
        order_object: 'purchase_order',
        order_code: this.detailsInfo.order_number,
        logistics_code: this.logisticsInfo.logistics_code
      }
      this.$http.DeleteAll(this.$api.orderLogistics, postObj, true).then(res => {
        this.queryDetails(this.id)
        this.deleteStatus = false
      })
    },
    // 点击删除
    deleteLogis(item) {
      this.logisticsInfo = item
      console.log(this.logisticsInfo)
      this.deleteStatus = true
    },
    // 点击添加物流
    addLogis() {
      this.logisTitle = '新增物流'
      this.logisticsInfo = {
        logistics_company: '顺风',
        logistics_code: '',
        delivery_time: ''
      }
      this.addState = true
    },
    // 物流详情
    showLogis(item) {
      this.logisTitle = '物流详情'
      this.logisticsInfo = item
      this.addState = true
    },
    // 添加物流
    addLogistics() {
      if (!this.logisticsInfo.logistics_code) {
        this.$Message.warning('请填写物流单号')
        return
      }
      if (!this.logisticsInfo.delivery_time) {
        this.$Message.warning('请选择发货时间')
        return
      }
      let postObj = {
        order_object: 'purchase_order',
        order_code: this.detailsInfo.order_number,
        logistics_info: this.logisticsInfo
      }
      this.$http.post(this.$api.orderLogistics, postObj, true).then(res => {
        this.addState = false
        this.$Message.success('新增物流成功')
        this.queryDetails(this.id)
      })
    },
    // 查询详情
    queryDetails(id) {
      this.$http.get(this.$api.pruchaseOrderDetail, { id: id }, true).then(res => {
        this.detailsInfo = res.data
        this.listData = res.data.purchase_order_line
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].value == this.detailsInfo.purchase_order_state) {
            this.nowIndex = i
          }
        }
      })
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="less">
.nav {
  margin: 12px 0 23px 0;
}
.other {
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #525b6d;
  line-height: 25px;
  margin: 20px 0;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  span {
    width: 80px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  span::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.iviewIptWidth373 {
  width: 373px !important;
}
</style>
